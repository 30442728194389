import React, {useEffect, useState} from 'react';
import "../css/pagescard.css";
import "../css/pagescardnavbar.css";
import PagesNavbar from "../components/pagescardnavbar";


function Suspension() {
    const [images, setImages] = useState([]);


    useEffect(() => {
        const fetchImages = async () => {
            try {
                // Aszinkron függvény a képek lekéréséhez a backend API-ból
                const response = await fetch('https://www.skoda110rcoupe.hu/api/suspensions');
                const data = await response.json();
                console.log('Fetched image paths:', data);
                setImages(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        // Képek lekérése az API-ból komponens betöltésekor
        fetchImages()
    }, []);

    return (
        <div className="suspensions-card">
            <div className="nav">
                <PagesNavbar/>
                <hr className="hr"/>
            </div>

            <main className="cards">
                {images.map((image, index) => (
                    <div key={index}>
                        <div className="card">
                            <a href={`${image.path}/${image.name}`} target="_blank" rel="noopener noreferrer">
                                <img src={`${image.path}/${image.name}`} className="card-img-top"
                                     alt={`Kép ${index + 1}`}/></a>
                        </div>
                    </div>
                ))}
            </main>
        </div>
    );
}

export default Suspension;
