import React, {useEffect, useState} from 'react';
import "../css/pagescardnavbar.css";
import "../css/pagescard.css";
import PagesNavbar from "../components/pagescardnavbar";



function Kaszni() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://www.skoda110rcoupe.hu/api/pictures');
                const data = await response.json();
                console.log('Fetched image paths:', data);
                setImages(data);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);

    return (
        <div className="kaszni-kartyak">
            <div className="nav">
                <PagesNavbar/>
                <hr className="hr"/>
            </div>
            <main className="cards row-cols-lg-1">
                {images.map((image, index) => (
                    <div key={index}>
                        <div className="col card">
                            <a href={`${image.path}/${image.name}`} target="_blank" rel="noopener noreferrer">
                                <img src={`${image.path}/${image.name}`} className="card-img-top"
                                     alt={`Kép ${index + 1}`}/>
                            </a>
                        </div>
                    </div>
                ))}
            </main>
        </div>
    )
        ;
}

export default Kaszni;