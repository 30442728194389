import React from 'react';
import '../css/leiras.css'
import {Link} from "react-router-dom";

function Leiras() {
    return (
        <div className="leiras">
            <h1 className='focim'>Skoda 110 R Coupe 1972</h1>
            <hr className='leiras-hr' style={{color: 'cornsilk'}}/>
            <div className="leiras-navbar">
                <div className="leiras-navitem">
                    <div className="leiras-kezdolap">
                        <Link className="nav-link" to="/">Kezdőlap</Link>
                    </div>
                    <div className="leiras-fenykepek">
                        <Link className="nav-link" to="/fenykepek">Fényképek</Link>
                    </div>
                </div>
            </div>
            <div className="szoveg">
                <div className="tablazat">
                    <div className="adatok">
                        <table>
                            <thead>
                            <tr>
                                <th style={{textAlign: "center"}}>ADATOK</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Évjárat: 1972</td>
                            </tr>
                            <tr>
                                <td>Motor: 1300 cm Favorit</td>
                            </tr>
                            <tr>
                                <td>Váltó: 4 sebességes (Gyári)</td>
                            </tr>
                            <tr>
                                <td>Felni: 15", 4 X 130 , VW Felni</td>
                            </tr>
                            <tr>
                                <td>Futómű: Olajos (Gyári)</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="leiras-szoveg">
                    <div className="main-keret">
                        <main style={{marginTop: "1vw", marginBottom: "1vw"}}>
                            <p className="leiras-p ">
                                <span style={{fontWeight: "Bold"}}>- A felújítás</span> 4 évig tartott. Fémig volt
                                csiszolva. A karosszéria főbb részeit karosszéria lakatos javította.
                                A fényezéssel kapcsolatban a tapasztalataim megosztóak voltak, baráti áron lett volna,
                                de az elképzeléseim miatt megállapította a fényező, hogy nekem festőre van szükségem
                                nem fényezőre.
                                Az elképzelésem, először az volt, hogy alul fényes fekete, a teteje meg pepita, de úgy,
                                mintha folyna le a tetejéről <>&#128522;</>. A tetejét sima fehérre még le is fújta
                                volna,
                                az alsó feketéről viszont próbált meggyőzni, hogy az se lenne nyerő, mert a feketén
                                minden
                                horpadás meglátszódna, és ezt elkerülendő, sok gitt és lemez munka lenne az eltüntetése,
                                ami ár és idő növelő lenne. Mindenféle egyéb világos színre szeretett volna rábeszélni.
                                A végén abba bele egyeztem volna, hogy egyszínű legyen, de akkor fényes fekete, de nem
                                szeretném, hogy a teljesen sima felületet gittel érje el, ezért mondtam neki, hogy nem
                                baj,
                                ha
                                nem gitteli
                                agyon és látszik rajta egy-két hiba, hiszen az autó akkor volt 48 éves, és nem lett
                                volna
                                probléma, ha ez
                                látszott volna a kasznin is, nem szerettem volna egy újabb kiállítási darabot,
                                csak legyen le fényezve. A fényező ebbe nem szeretett volna bele menni, arra hivatkozva,
                                hogy ez
                                az ő munkáját minősítené ha valaki megtudná, hogy ő fényezte és ilyen munkát ad ki,
                                megértettem
                                amit mondott, és neki
                                álltam magam az elemek lefújásának is. Tetszenek azok a veterán autók is, amik
                                makulátlanok,
                                viszont én úgy gondoltam, hogy nekem több örömöt ad, ha úgy tudom használni,
                                hogy nem kell félnem attól, hogy valaki nézegetés közben véletlenül megsérti a
                                fényezést<>&#128522;</>
                                (persze nem azt mondom, hogy nem féltem, de így egyszerűbb a
                                javítása.)<br/>
                                <span style={{fontWeight: "Bold"}}>- Nagyon</span> tetszenek az egyedi kinézetű veterán
                                autók,
                                mint például a Rat Style , Hot Rod, vagy a
                                Steampunk autók, amikbe benne van egy kicsit a tulajdonos egyénisége is.
                                Ezért döntöttem úgy, hogy az Amerikai "csináld magad" fényezési technikát alkalmazom.
                                Kell egy helyiség egy kompresszor
                                egy szóró pisztoly és önbizalom, aztán csak csináld<>&#128522;</>
                                . Eddig is fekete volt,
                                csak fényes most a matt fekete mellett döntöttem, abból is szintén Amerikai ötlet után a
                                Raptor
                                plató festék tetszett meg, nagyon tetszett a felülete, olyan, mint az alvázvédő csak ez
                                ellenáll
                                az UV-nak,
                                rugalmas és mégis kemény felületet ad. A Raptor festéket nem tudtam beszerezni, viszont
                                a
                                Body
                                márkának is volt plató védője, hasonló tulajdonsággal. Ahol vettem, ott, egy ott dolgozó
                                fényezővel beszéltem, ő is hallott már erről, hogy komplett autót átfújnak vele.
                                Elmondta,
                                hogy hogy lehet finomítani a szemcsén, hogy ránézésre ne igazán tűnjön
                                egyből alvázvédőnek (<>&#128522;</>
                                még ha az is). Hát ilyen lett. Azért nem teljesen gitt
                                mentes a felület. Tettem én is rá egy keveset, mert
                                hát, ugye ha érez az ember magában affinitást bármilyen dolgok iránt, szereti magának
                                bebizonyítani, hogy jól gondolja-e, és meg tudja valósítani, amit elképzelt. Volt egy
                                kitűzött
                                cél is, ami a
                                Skoda Klub által szervezett <a
                                href="https://euroring.hu/versenypalya/auto-nyilt-nap/"> Euro
                                Ringen</a>lévő, Skoda Klubos verseny volt. Ahhoz hogy itt, egy
                                komplett autóval tudjak részt venni, ennek volt az az ára hogy maradtak lecsiszolatlan
                                gittelt
                                felületek ,
                                de legalább el tudtunk indulni vele az <a
                                href="https://euroring.hu/versenypalya/berles/">Euro
                                Ringen.</a> Ami nagy visszaigazolás volt arra,
                                hogy jól sikerült az összeszerelés, és tényleg meg tudtam valósítani az elképzeléseimet,
                                mert kibírta az oda utat (III, kerületből), az Euró Ringen való meneteket és a vissza
                                utat
                                is.
                                Azóta indultunk vele a <a href="https://www.szeszvame.hu/">SZESZVAME</a> által
                                szervezett
                                veterán autó túrákon, amely minden évben
                                kétszer kerül megrendezésre, az egyik egy bemelegítő Zsíros kenyér futam, majd egy
                                hosszabb
                                távú
                                Dunakanyar futam.

                            </p>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default Leiras;