import React from 'react';
import {Link} from 'react-router-dom';
import '../css/fenykepek.css';
import "../css/pagescardnavbar.css";
import Carousel from '../components/carousel';
import PagesNavbar from "../components/pagescardnavbar";

function Fenykepek() {
    return (
        <div className="container-fluid kartyak" style={{width: "100%"}}>
            {/* Navigation Buttons */}
            <PagesNavbar/>
            {/* Cards Section */}
            <div className="row row-cols-lg-5 row-cols-md-2 row-cols-2 g-4 justify-content-center">
                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/kaszni.png")} alt="kaszni"/>
                        <Link to="/kaszni" className="btn">Kaszni</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/muszerfal_tabla.png")}
                             alt="interior"/>

                        <Link to="/interior" className="btn">Kárpit</Link>

                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-bottom img-fluid" src={require("../kepek/futomu01.jpg")}
                             style={{borderRadius: "25px"}} alt="futomu"/>
                        <Link to="/suspension" className="btn">Futómű</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-top img-fluid" src={require("../kepek/hamarosan.png")}
                             alt="hamarosan"/>
                        <Link to="/movies" className="btn">Videok</Link>
                    </div>
                </div>

                <div className="col">
                    <div className="card">
                        <img className="card-img-bottom img-fluid" src={require("../kepek/skoda.png")}
                             style={{borderRadius: "25px"}} alt="car"/>
                        <Link to="/car" className="btn car" style={{width: "fit-content"}}>Skoda 110 R Coupe</Link>
                    </div>
                </div>

                {/* Carousel Component */}
                <div className="col carousel">
                    <Carousel/>
                </div>
            </div>
        </div>
    );
}


export default Fenykepek;
