import React from 'react';
import MyNavbar from "../components/navbar";
import '../css/szelepFedelMove.css';
import '../css/fooldal.css';

function Fooldal() {
    return (
        <div className="container-fluid fooldal">
            <div className="fooldal-navbar>">
                <MyNavbar/>
            </div>
            <div className="fooldal-kepek position-relative">
                <img className='img-fluid szelepfedel' src={require('../kepek/szelepfedel_kismeret.png')}
                     style={{width: "100%", maxWidth: "700px", position: "relative", zIndex: 1}}
                     alt={'szelepfedel'}/>

                <img className='img-fluid skoda' src={require('../kepek/skodahdr1.png')}
                     alt={'skoda110'}/>
            </div>
        </div>
    );
}

export default Fooldal;
