// navbar.js
import '../css/pagescardnavbar.css';
import React from 'react';
import {Link} from 'react-router-dom';

function PagesNavbar() {
    return (
        <div className="pages-navbar">
            <ul className="pagesnavbar-nav">
                <li className="pagesnav-item">
                    <Link className="pagesnav-link" to="/">Kezdőlap</Link>
                </li>
                <li className="pagesnav-item">
                    <Link className="pagesnav-link" to="/fenykepek">Fényképek / Videók</Link>
                </li>
                <li className="pagesnav-item">
                    <Link className="pagesnav-link" to="/leiras">Leírás</Link>
                </li>
            </ul>
        </div>
    )
        ;
}

export default PagesNavbar;